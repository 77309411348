import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken } from '../../../Utils/Common'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import { confirm } from "../../../Utils/Confirmation"

import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import moment from 'moment'

interface MembersMembershipToUpdateProps {
  id: string
}

const MembersMembershipToUpdate: React.FC<MembersMembershipToUpdateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci


  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)

  // Připojení store
  const rootStore = useStore()
  const member = rootStore.getDancerCALById(props.id)
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  

  // Funkce pro změnu kontaktu
  const handleUpdate = (values:any) => {
    setError(null)
    setLoading(true);
    
    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      //const aToken = await getToken()
      if (localStorage.getItem('isLogged') !== 'is') { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      
      // Zavolání API
      await axios.post(Constants.URL_DANCER_CAL_ADD_PAYMENT, { idMembers: props.id, variableSymbol: values.variableSymbol, price: values.price, year: values.year, note: values.note, sendEmail: values.sendEmail === true ? 1:0,  userId: rootStore.loggedUsers[0]?.idUsers, userPassword: localStorage.getItem('PIN')!}
        ).then(response => {
          
          // Změna dat ve Store
          
          rootStore.fetchDancerListCAL(rootStore.loggedUsers[0]?.idUsers, localStorage.getItem('PIN')!)

          //rootStore.fetchCustomerOpened()  
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
    })()
  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    /*surName: Yup.string()
      .required('Příjmení musí být vyplněno!'),*/
    /*
    value: Yup.string()
    .required('Hodnota musí být vyplněna!'),*/
  })   
  return (
    <>
      
        <>
          <Button variant="success" size="sm" onClick={handleShow}>
            <i className="fas fa-money-bill-alt"></i>
          </Button>
          <span style={{marginLeft: '10px'}}></span>
          
        </>
      
      
      

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Platba sportovce {member?.id} - {member?.surName} {member?.firstName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Sportovec byl úspěšně upraven!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                
                
                variableSymbol: member?.id,
                price: 300,
                year: new Date().getFullYear(), 
                note: '',
                sendEmail: false
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values)              
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
               
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  
                  <FormGroup controlId="year">
                    <Form.Row>
                      <FormLabel column lg={3}>Přispěvky na rok</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="year"
                          value={values.year!}
                          onChange={handleChange}
                          isValid={touched.year && !errors.year} 
                          isInvalid={!!errors.year}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.year}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="price">
                    <Form.Row>
                      <FormLabel column lg={3}>Částka</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="price"
                          value={values.price!}
                          onChange={handleChange}
                          isValid={touched.price && !errors.price} 
                          isInvalid={!!errors.price}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.price}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="variableSymbol">
                    <Form.Row>
                      <FormLabel column lg={3}>Variabilní symbol</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="variableSymbol"
                          value={values.variableSymbol!}
                          onChange={handleChange}
                          isValid={touched.variableSymbol && !errors.variableSymbol} 
                          isInvalid={!!errors.variableSymbol}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.variableSymbol}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="note">
                    <Form.Row>
                      <FormLabel column lg={3}>Poznámka</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="note"
                          value={values.note!}
                          onChange={handleChange}
                          isValid={touched.note && !errors.note} 
                          isInvalid={!!errors.note}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.note}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <hr />

                  <FormGroup controlId="sendEmail">
                    <Form.Row>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="sendEmail"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          //onChange={async(e) => {await handleChange(e); validateField('yesTwo');}}
                          //onBlur={() =>validateField('yesTwo')}
                          checked={values.sendEmail}
                          
                          isValid={touched.sendEmail && !errors.sendEmail} 
                          isInvalid={!!errors.sendEmail}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.sendEmail}
                        </Form.Control.Feedback>
                        </Col>
                        <Col>
                          Odeslat e-mail s notifikací
                        </Col>
                    </Form.Row>  
                  </FormGroup>
                   
                   
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(MembersMembershipToUpdate))
