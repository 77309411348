import React, { useState } from 'react'
import axios from 'axios'

import { useParams } from 'react-router'
//import { Link } from 'react-router-dom'

import HomeInfo from '../../../parts/homeInfo/HomeInfo.parts'
import ErrorAlert from '../../../parts/errorAlert/ErrorAlertWithoutLogout.part'
import {Container,  Form, Col, Button, Alert, Image} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import * as Constants from '../../../Constants/Constants'

import { useMount } from '../../../helpers/lifecycle-hooks'

import { useStore } from '../../../store'

import { Formik } from 'formik'
import * as Yup from 'yup'

import Recaptcha from 'react-recaptcha'

import Loader from 'react-loader'
import { FinishRegistrationUrlParams } from '../../../Routes'

function RegistrationFinishCAL(props:any) {

    const { idMembers } = useParams<FinishRegistrationUrlParams>()
    const { secureCode } = useParams<FinishRegistrationUrlParams>()

  const [loaded, setLoaded] = useState(true);
  
  const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  
  // Získání tokenu z URL adresy
  

  // Kontrola zda se načetla URL BE
  if (sessionStorage.getItem('URL') === null) {
    window.location.reload();
  }

  const rootStore = useStore()

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showE, setShowE] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [showErrorInfo, setShowErrorInfo] = useState(false)

  const [showForm, setShowForm] = useState('')
  const [emailFromToken, setEmailFromToken] = useState('')

  const [aFirstName, setAFirstName] = useState('')
  const [aSurName, setASurName] = useState('')
  const [aClub, setAClub] = useState('')
  const [aCountry, setACountry] = useState('')

    // Při načtení stránky 
    useMount(() => {
      (async () => {  
      setLoaded(false) // Nastavení pro Loader, že není strana načtena
      window.scrollTo(0, 0)
      setShowForm('')
      // Dotažení e-mailu z tokenu
      await rootStore.fetchCountries()

      await axios.get(Constants.URL_MEMBERS_SOLO_DUO_DETAIL_TO_FINISH_REG,{params: {
        idMembers: idMembers,
        secureCode: secureCode
  }}
      ).then(response => {
        // Pokud OK, zobrazit potvrzovací hlášení a po 10s přepnout na loginpage
        //setShow(false)
        
        //setShowTrue(true)

        setAFirstName(response.data.firstName)
        setASurName(response.data.surName)
        setAClub(response.data.club)
        setACountry(response.data.country)

        setTimeout(() => {
          //props.history.push('/login');  
        }, 10000);
      }).catch(error => {
        setShowForm('nodisplay')
        setShowErrorInfo(true)
        //setShow(false);
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)
      });
      
      setLoaded(true) // Nastavení pro Loader, že je strana načtena
      })() 
    })

  // Po odeslání registračního formuláře
  const handleInsert = (values:any) => {
    setError(null)
    setLoading(true)

    // Zavolání API
    //axios.post(Constants.URL_REGISTRATION_ADD, { surName: values.surName, firstName: firstNameData, birthdate: birthdateData, personalIdentificationNumber: personalIdentificationNumberData, club: clubData, country: countryData, email: emailData, phone: phoneData}
    axios.post(Constants.URL_REGISTRATION_CAL_FINISH, { 
      idMembers: idMembers,
      secureCode: secureCode,
      status: values.status,
      soloDuo: ['1','3'].includes(values.status) ? 1:0,
      couples: ['2','3'].includes(values.status) ? 1:0,
      surName: values.surName, 
      firstName: values.firstName, 
      birthdate: values.birthdate, 
      personalIdentificationNumber: values.personalIdentificationNumber, 
      club: values.club, 
      country: values.country, 
      email: values.email, 
      phone: values.phone, 
      street: values.street, 
      houseNumber: values.houseNumber,  
      identificationNumber: values.identificationNumber,
      postalCode: values.postalCode, 
      city: values.city, 
      yesOne: values.yesOne === true ? 1:0, 
      yesTwo: values.yesTwo === true ? 1:0, 
      yesThree: values.yesThree === true ? 1:0, 
      yesFour: values.yesFour === true ? 1:0,
      adultPerson: values.adultPerson,
    }
    ).then(response => {
      setLoading(false);
      setShowInfo(true)
      setShowForm('nodisplay')
    }).catch(error => {
      setLoading(false);
      const errorMessage:any = (<ErrorAlert errorData={error} />)
      setError(errorMessage)
    });
  }

// Pomocná funkce pro výpočet věku na základě data narození
const calculateAge = (birthDate: any): number => {
  console.log(birthDate)
  if (!birthDate) return 0; // Pokud není zadané datum, vrátíme věk 0
  const today = new Date();
  const birth = new Date(birthDate);
  let age = today.getFullYear() - birth.getFullYear();
  const monthDiff = today.getMonth() - birth.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
    age--;
  }
  console.log(age)
  return age;
};

  // Schéma validace formuláře
  const schema = Yup.object().shape({
    status: Yup.string()
      .required('Typ členství musí být vyplněn!'),
    surName: Yup.string()
      .required('Příjmení musí být vyplněno!'),
    firstName: Yup.string()
      .required('Jméno musí být vyplněno!'),
    birthdate: Yup.date().required("Datum narození musí být vyplněno!"),
    personalIdentificationNumber: Yup.string()
      .required("Rodné číslo musí být vyplněno!")
      .matches(/^([0-9]{1,100})$/, 'Rodné číslo může obsahovat pouze číslice'),
    club: Yup.string()
      .required('Taneční klub musí být vyplněn! Pokud v žádném nejste, zadejte město.'),
    email: Yup.string()
      .required('E-mail musí být vyplněn!')
      .email('E-mailová adresa je ve špatném formátu!'),
    phone: Yup.string()
      .required('Telefon musí být vyplněn!'),
    recaptcha: Yup.string().required('Ověření je vyžadováno!'), 

    street: Yup.string()
      .required('Ulice musí být vyplněna!'),
    houseNumber: Yup.string()
      .required('Číslo popisné musí být vyplněno!'),
    postalCode: Yup.string()
      .required('PSČ musí být vyplněno!'),
    city: Yup.string()
      .required('Město musí být vyplněno!'),
    yesOne: Yup.boolean()
      .oneOf([true], 'Musíte souhlasit se stanovami spolku!')
      .required('Musíte souhlasit se stanovami spolku!'),
    yesTwo: Yup.boolean()
      .oneOf([true], 'Musíte souhlasit se zpracováním osobních údajů!')
      .required('Musíte souhlasit se zpracováním osobních údajů!'),
    yesThree: Yup.boolean()
      .oneOf([true], 'Musíte souhlasit s využitím osobních údajů!')
      .required('Musíte souhlasit s využitím osobních údajů!'),
    yesFour: Yup.boolean()
      .oneOf([true], 'Musíte souhlasit s využitím osobních údajů!')
      .required('Musíte souhlasit s využitím osobních údajů!'),
    adultPerson: Yup.string()
      .when('birthdate', {
        is: (birthdate: any) => calculateAge(birthdate) < 18,
        then: Yup.string().required('U členů mladších 18 let musí být vyplněno jméno a vztah osoby, která vyplňuje formulář!'),
        otherwise: Yup.string().nullable(),
      }),  
    
  })  

  return (
    <div className="App">
      <main>
      <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
      <Container fluid className="nopadding">
        <div className="row no-gutter">
          <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image">
            <div className="login d-flex align-items-center py-5">
              <Container className="login-text-white login-container">
                <div className="row">
                  <div className="mx-auto">
                    
                    <div>
                      
                    </div>
                  </div>
                </div>
              
              </Container>
            </div>  
          </div>
          <div className="col-md-8 col-lg-6">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <h2 className="login-text-vertical-center"><Image src="images/cal-small.png" width={130} className="login-logo" /> Informační systém</h2>
                    <hr />
                    <br />
                    <h3 className="login-heading mb-4">Dokončení registrace a přihlášky individuálního člena</h3>
                    
                    <div className={showForm} >
                      <p>Přihláška individuálního člena do sportovní organizace: Czech Amateur League, pobočný spolek SUT (Svazu učitelů tance ČR) - dále jen ”Spolek”, IČO: 09779400</p>
                    </div>
                    <br />
                    {showE === true ? error : ''}
                    <Alert variant="success" show={showInfo}>
                            <strong>Děkujeme!</strong><br />
                            Registrace byla dokončena.
                    </Alert> 
                    <Alert variant="danger" show={showErrorInfo}>
                            <strong>Neplatný odkaz!</strong><br />
                            Odkaz, který jste použili není platný, nebo již byla registrace dokončena!
                    </Alert> 
                    <div className={showForm} >
                    <Formik
              validationSchema={schema}
              initialValues={{ 
                id: idMembers,
                surName: aSurName, 
                firstName: aFirstName, 
                birthdate: '',
                personalIdentificationNumber: '',
                club: aClub,
                country: aCountry,
                email: '',
                phone: '',
                recaptcha: '',
                status: '',
                street: '',
                houseNumber: '',
                identificationNumber: '',
                postalCode: '',
                city: '',
                yesOne: false,
                yesTwo: false,
                yesThree: false,
                yesFour: false,
                adultPerson: '',

              }}
              validateOnChange={false}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleInsert(values)              
                  setSubmitting(false);
                }, 10);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                validateField,
                validateForm,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="status">
                  <Form.Row>
                    <h5 style={{fontWeight: 'bold'}}>Základní údaje</h5>
                  </Form.Row>
                  </FormGroup>
                  <FormGroup controlId="id">
                    <Form.Row>
                      <FormLabel column lg={3}>ID</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="id"
                          value={values.id!}
                          onChange={async(e) => {await handleChange(e); validateField('id');}}
                          onBlur={() =>validateField('id')}
                          isValid={touched.id && !errors.id} 
                          isInvalid={!!errors.id}
                          disabled={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.id}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>  
                  <FormGroup controlId="status">
                    <Form.Row>
                      <FormLabel column lg={3}>Typ členství</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="status"
                          autoComplete="off" 
                          value={values.status}
                          onChange={async(e) => {await handleChange(e); validateField('status');}}
                          onBlur={() =>validateField('status')}
                          
                          isValid={touched.status && !errors.status} 
                          isInvalid={!!errors.status}
                          //disabled={selectedMember === '' ? false:true}
                          >
                            <option value="">--- Vyberte ---</option>
                            <option value="1">Sólo & Duo Dance</option>
                            <option value="2">Párové tance</option>
                            <option value="3">Sólo & Duo Dance + Párové tance</option>
                            

                          </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.status}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>             
                    
                  <FormGroup controlId="surName">
                    <Form.Row>
                      <FormLabel column lg={3}>Příjmení</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="surName"
                          value={values.surName!}
                          onChange={async(e) => {await handleChange(e); validateField('surName');}}
                          onBlur={() =>validateField('surName')}
                          isValid={touched.surName && !errors.surName} 
                          isInvalid={!!errors.surName}
                          disabled={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.surName}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>            

                  <FormGroup controlId="firstName">
                    <Form.Row>
                      <FormLabel column lg={3}>Jméno</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="firstName"
                          value={values.firstName!}
                          onChange={async(e) => {await handleChange(e); validateField('firstName');}}
                          onBlur={() =>validateField('firstName')}
                          isValid={touched.firstName && !errors.firstName} 
                          isInvalid={!!errors.firstName}
                          disabled={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>  
                  <FormGroup controlId="birthdate">
                    <Form.Row>
                      <FormLabel column lg={3}>Datum narození</FormLabel>
                      <Col>
                        <FormControl 
                          type="date"
                          name="birthdate"
                          value={values.birthdate!}
                          onChange={async(e) => {await handleChange(e); await validateField('birthdate'); validateField('adultPerson');}}
                          onBlur={async () => { validateField('birthdate'); validateField('adultPerson');}}
                          isValid={touched.birthdate && !errors.birthdate} 
                          isInvalid={!!errors.birthdate}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.birthdate}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="personalIdentificationNumber">
                    <Form.Row>
                      <FormLabel column lg={3}>Rodné číslo</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="personalIdentificationNumber"
                          value={values.personalIdentificationNumber!}
                          onChange={async(e) => {await handleChange(e); validateField('personalIdentificationNumber');}}
                          onBlur={() =>validateField('personalIdentificationNumber')}
                          isValid={touched.personalIdentificationNumber && !errors.personalIdentificationNumber} 
                          isInvalid={!!errors.personalIdentificationNumber}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.personalIdentificationNumber}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="club">
                    <Form.Row>
                      <FormLabel column lg={3}>Taneční klub</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="club"
                          value={values.club!}
                          onChange={async(e) => {await handleChange(e); validateField('club');}}
                          onBlur={() =>validateField('club')}
                          isValid={touched.club && !errors.club} 
                          isInvalid={!!errors.club}
                          disabled={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.club}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="country">
                    <Form.Row>
                      <FormLabel column lg={3}>Stát</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="country"
                          autoComplete="off" 
                          value={values.country}
                          //onChange={handleChange}
                          onChange={handleChange}
                          //onBlur={() =>validateField('country')}
                          
                          isValid={touched.country && !errors.country} 
                          isInvalid={!!errors.country}
                          disabled={true}
                          //disabled={selectedMember === '' ? false:true}
                          >
                            {rootStore.countries
                            .filter(c => c.priority < 99)
                            .sort((a, b) => {
                              //return a.priority - b.priority;
                              if (a.priority! < b.priority!) {
                                  return -1;
                              }
                              if (a.priority! > b.priority!) {
                                  return 1;
                              }
                              if (a.finalCode < b.finalCode) {
                                return -1;
                              }
                              if (a.finalCode > b.finalCode) {
                                  return 1;
                              } 
                              return 0;
                              })
                            .map(c => (<option value={c.finalCode}>{c.finalCode} - {c.nameCz}</option>))}
                            <option value="X" disabled>-----{'Další státy'}-----</option>
                            {rootStore.countries
                            .filter(c => c.priority > 99)
                            .sort((a, b) => {
                              //return a.priority - b.priority;
                              if (a.priority! < b.priority!) {
                                  return -1;
                              }
                              if (a.priority! > b.priority!) {
                                  return 1;
                              }
                              if (a.finalCode < b.finalCode) {
                                return -1;
                              }
                              if (a.finalCode > b.finalCode) {
                                  return 1;
                              } 
                              return 0;
                              })
                            .map(c => (<option value={c.finalCode}>{c.finalCode} - {c.nameCz}</option>))}
                            
                        </Form.Control>
                        
                        
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.country}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>                  
                  <FormGroup controlId="email">
                    <Form.Row>
                      <FormLabel column lg={3}>E-mail</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="email"
                          value={values.email!}
                          //onChange={handleChange}
                          onChange={async(e) => {await handleChange(e); validateField('email');}}
                          onBlur={() =>validateField('email')}
                          isValid={touched.email && !errors.email} 
                          isInvalid={!!errors.email}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>  
                  <FormGroup controlId="phone">
                    <Form.Row>
                      <FormLabel column lg={3}>Telefon</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="phone"
                          value={values.phone!}
                          //onChange={handleChange}
                          onChange={async(e) => {await handleChange(e); validateField('phone');}}
                          onBlur={() =>validateField('phone')}
                          isValid={touched.phone && !errors.phone} 
                          isInvalid={!!errors.phone}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.phone}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>  

                  <FormGroup controlId="address">
                    <Form.Row>
                      <h5 style={{fontWeight: 'bold'}}>Adresa</h5>
                    </Form.Row>
                  </FormGroup>
                  <Form.Row>
                    <FormGroup as={Col}  controlId="street">
                      <FormLabel>Ulice</FormLabel>
                      <FormControl 
                        type="text"
                        name="street"
                        value={values.street!}
                        //onChange={handleChange}
                        onChange={async(e) => {await handleChange(e); validateField('street');}}
                        onBlur={() =>validateField('street')}
                        isValid={touched.street && !errors.street} 
                        isInvalid={!!errors.street}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.street}
                      </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} lg={2} controlId="houseNumber">
                      <FormLabel>ČP</FormLabel>
                        <FormControl 
                          type="text"
                          name="houseNumber"
                          value={values.houseNumber!}
                          //onChange={handleChange}
                          onChange={async(e) => {await handleChange(e); validateField('houseNumber');}}
                          onBlur={() =>validateField('houseNumber')}

                          isValid={touched.houseNumber && !errors.houseNumber} 
                          isInvalid={!!errors.houseNumber}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.houseNumber}
                        </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup as={Col} lg={2} controlId="identificationNumber">
                        <FormLabel>ČO</FormLabel>
                        <FormControl 
                          type="text"
                          name="identificationNumber"
                          value={values.identificationNumber!}
                          onChange={handleChange}
                            
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.identificationNumber}
                        </Form.Control.Feedback>
                    </FormGroup>
                  </Form.Row>

                  <Form.Row>  
                    <FormGroup as={Col} lg={3} controlId="postalCode">
                      <FormLabel>PSČ</FormLabel>
                      <FormControl 
                        type="text"
                        name="postalCode"
                        value={values.postalCode!}
                        //onChange={handleChange}
                        onChange={async(e) => {await handleChange(e); validateField('postalCode');}}
                        onBlur={() =>validateField('postalCode')}

                        isValid={touched.postalCode && !errors.postalCode} 
                        isInvalid={!!errors.postalCode}
                      />
                        
                      <Form.Control.Feedback type="invalid">
                        {errors.postalCode}
                      </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup as={Col} controlId="city">
                        <FormLabel>Město</FormLabel>
                        <FormControl 
                          type="text"
                          name="city"
                          value={values.city!}
                          //onChange={handleChange}
                          onChange={async(e) => {await handleChange(e); validateField('city');}}
                          onBlur={() =>validateField('city')}
                          isValid={touched.city && !errors.city} 
                          isInvalid={!!errors.city}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.city}
                        </Form.Control.Feedback>
                      </FormGroup> 
                    </Form.Row>             




                    <FormGroup controlId="address">
                    <Form.Row>
                      <h5 style={{fontWeight: 'bold'}}>Souhlasy</h5>
                    </Form.Row>
                  </FormGroup>

                  <FormGroup controlId="yesOne">
                    <Form.Row>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="yesOne"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          //onChange={handleChange}
                          onChange={async(e) => {await handleChange(e); validateField('yesOne');}}
                          onBlur={() =>validateField('yesOne')}
                          checked={values.yesOne}
                          
                          isValid={touched.yesOne && !errors.yesOne} 
                          isInvalid={!!errors.yesOne}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.yesOne}
                        </Form.Control.Feedback>
                        </Col>
                        <Col>
                          Odesláním tohoto formuláře potvrzuji, že jsem se seznámil se stanovami spolku, souhlasím s nimi a chci se stát členem.
                          <br />Odkaz na&nbsp;
                          <a href="https://docs.google.com/document/d/1n3f6PcW1VOqsbl4iFkPj-Y9lEQNdXWdLDcS99QJ1i_0/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Stanovy spolku</a>.

                        </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="yesTwo">
                    <Form.Row>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="yesTwo"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          //onChange={handleChange}
                          onChange={async(e) => {await handleChange(e); validateField('yesTwo');}}
                          onBlur={() =>validateField('yesTwo')}
                          checked={values.yesTwo}
                          
                          isValid={touched.yesTwo && !errors.yesTwo} 
                          isInvalid={!!errors.yesTwo}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.yesTwo}
                        </Form.Control.Feedback>
                        </Col>
                        <Col>
                          Souhlasím se správou, zpracováním a uchováváním svých osobních údajů pro vnitřní potřeby zapsaného spolku. Tyto činnosti budou prováděny v souladu s příslušnými právními předpisy, zejména se zákonem 101/2000 Sb. o ochraně osobních údajů, v platném znění a Nařízením Evropského parlamentu a Rady (EU) 2016/679 ze dne 27. dubna 2016 O ochraně fyzických osob v souvislosti se zpracováním osobních údajů a volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (dále jen „Nařízení“). Tento souhlas je poskytnut na dobu mého členství v zapsaném spolku a dále ještě po dobu 2 let. Potvrzuji, že všechny údaje jsou pravdivé a aktuální k dnešnímu dni.
                        </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="yesThree">
                    <Form.Row>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="yesThree"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          //onChange={handleChange}
                          onChange={async(e) => {await handleChange(e); validateField('yesThree');}}
                          onBlur={() =>validateField('yesThree')}
                          checked={values.yesThree}
                          
                          isValid={touched.yesThree && !errors.yesThree} 
                          isInvalid={!!errors.yesThree}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.yesThree}
                        </Form.Control.Feedback>
                        </Col>
                        <Col>
                          Souhlasím, aby v souladu s příslušnými právními předpisy a Nařízením Spolek zpracoval moje: fotografie, videa, zvukové záznamy, sportovní výsledky za účelem marketingu, prezentace na webu, prezentace na sociálních sítích, prezentace ve výroční zprávě a dalších informačních materiálech.
                        </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="yesFour">
                    <Form.Row>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="yesFour"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          //onChange={handleChange}
                          onChange={async(e) => {await handleChange(e); validateField('yesFour');}}
                          onBlur={() =>validateField('yesFour')}
                          checked={values.yesFour}
                          
                          isValid={touched.yesFour && !errors.yesFour} 
                          isInvalid={!!errors.yesFour}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.yesFour}
                        </Form.Control.Feedback>
                        </Col>
                        <Col>
                        Souhlasím, aby Správce zpracoval i mé: telefonní číslo, e-mail, rodné číslo, adresu za účelem vedení evidence členské základny, členských příspěvků a s tím souvisejícími činnostmi. Souhlasím, aby údaje byly zpracovávány a uchovávány po celou dobu trvání účelu zpracování (tedy i po tom, kdy přestanu vykonávat činnost, pro kterou jsem Správcem evidován).
                        </Col>
                    </Form.Row>  
                  </FormGroup>


                  <FormGroup controlId="noAdult">
                    <Form.Row>
                      <h5 style={{fontWeight: 'bold'}}>Nezletilá osoba</h5>
                      V případě, že vyplňujete jménem nezletilé osoby, uveďte prosím Vaše celé jméno a vztah k zapisované osobě.
                    </Form.Row>
                  </FormGroup>
                  
                  <FormGroup controlId="adultPerson">
                    <Form.Row>
                      <FormLabel column lg={3}>Jméno</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="adultPerson"
                          value={values.adultPerson!}
                          //onChange={handleChange}
                          onChange={async(e) => {await handleChange(e); validateField('adultPerson');}}
                          onBlur={() => validateField('adultPerson')}
                          isValid={touched.adultPerson && !errors.adultPerson} 
                          isInvalid={!!errors.adultPerson}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.adultPerson}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>  

                  <br /><br />


                  <FormGroup controlId="recaptcha">
                    <Form.Row>
                    
                      <Col>
                      <Recaptcha
                  sitekey="6LeZxTMcAAAAAIlveWP5kv9e7fTtC5dTyTYATvWi"
                  render="explicit"
                  hl="cs"
                  verifyCallback={async (response) => { await setFieldValue("recaptcha", response); validateField('recaptcha'); }}
                  onloadCallback={() => { console.log("done loading!"); }}
                />
                {errors.recaptcha 
                  && touched.recaptcha && (
                  <p className="invalid-feedback" style={{display: "block"}}>{errors.recaptcha}</p>
                )}
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.recaptcha}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Odesílám...' : 'Odeslat registraci'}</Button>
                  
                </Form>
              )}
            </Formik>  
                    </div>    
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Container>   
        </Loader>
      </main>
    </div>
  )
}

export default RegistrationFinishCAL